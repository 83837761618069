import $ from "jquery";
import { page_params } from "./page_params";

export let csrf_token: string | undefined;

$(() => {
    // This requires that we used Jinja2's {% csrf_input %} somewhere on the page.
    const $csrf_input = $('input[name="csrfmiddlewaretoken"]');
    csrf_token = $csrf_input.attr("value");
    if (csrf_token !== undefined) {
        $.ajaxSetup({
            beforeSend(xhr: JQuery.jqXHR, settings: JQuery.AjaxSettings) {
                if (settings.url === undefined || csrf_token === undefined) {
                    throw new Error("settings.url and/or csrf_token are missing.");
                }


                xhr.setRequestHeader("X-CSRFToken", csrf_token);

                console.log(page_params.HTTP_X_SESSIONID)
                xhr.setRequestHeader("X-SESSIONID", page_params.HTTP_X_SESSIONID);

                if (!(/^http:.*/.test(settings.url) || /^https:.*/.test(settings.url))) {
                    // Only send the token to relative URLs i.e. locally.

                }
            },
        });
    }

    // added by qinke. zulip
    // let session_token = page_params.HTTP_X_SESSIONID;
    // if (session_token !== undefined) {
    //     $.ajaxSetup({
    //         beforeSend(xhr: JQuery.jqXHR, settings: JQuery.AjaxSettings) {
    //             console.log(session_token)
    //             // xhr.setRequestHeader("HTTP_X_SESSIONID", page_params.HTTP_X_SESSIONID);
    //         },
    //     });
    // }


});
